import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../css/title.scss';

const Title = ({ title, h1, subTitle }) => {
  return (
    <div
      className={classNames({
        'section-title': true,
        header: h1,
      })}
    >
      {h1 ? (
        <>
          {title && <h1>{title || 'default title'}</h1>}
          {subTitle && <h2>{subTitle}</h2>}
        </>
      ) : (
        <>
          {title && <h2>{title || 'default title'}</h2>}
          {subTitle && <h3>{subTitle}</h3>}
          {/* <div className="underline"></div> */}
        </>
      )}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  h1: PropTypes.bool,
  subTitle: PropTypes.string,
};

Title.defaultProps = {
  title: '',
  h1: false,
  subTitle: '',
};

export default Title;
